require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
import { Helpers } from "src/helpers";
import { Menu } from "src/menu";
import { Utility } from "src/utility";
import PerfectScrollbar from 'perfect-scrollbar';

import "@hotwired/turbo-rails"

// Configure Stimulus development experience
import { Application } from "@hotwired/stimulus"
const application = Application.start()

application.debug = false
window.Stimulus = application

const controllers = require.context("../controllers", true, /_controller\.js$/)
controllers.keys().forEach((fileName) => {
  const controllerModule = controllers(fileName)
  const controllerName = fileName.replace(/(^\.\/|_controller\.js$)/g, "")

  application.register(controllerName, controllerModule.default)
})

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


window.bootstrap = require('bootstrap');
window.Helpers = Helpers
window.PerfectScrollbar = PerfectScrollbar
window.Menu = Menu
window.Utility = Utility

require('src/main');
require('src/modal');
require('src/avatar-uploader');
require('parsleyjs/dist/parsley')
Utility.showToast();

// Load Datatables
// https://github.com/jbox-web/ajax-datatables-rails/blob/master/doc/webpack.md
require('datatables.net-bs')
require('datatables.net-buttons-bs')
require('datatables.net-buttons/js/buttons.colVis.js')
require('datatables.net-buttons/js/buttons.html5.js')
require('datatables.net-buttons/js/buttons.print.js')
require('datatables.net-responsive-bs')
require('datatables.net-select')
